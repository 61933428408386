import { defThunkRequest } from 'utils/defAsyncAction';
import { Quote } from 'types/quotes';
import { IPaginationResponse } from 'types/otherData';
import {
  getQuotesApiRequest,
} from '../apiRequests';
import {
  getQuotesActions,
} from './actionTypes';

export const getQuotesThunk = defThunkRequest<{submissionId: string}, IPaginationResponse<Quote>>({
  actionTypes: getQuotesActions,
  thunkSteps: [
    ({ data: { submissionId } }) => getQuotesApiRequest(submissionId),
  ],
});
